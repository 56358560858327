import { useMemo, forwardRef, Ref, ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import FormInput, { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

type FormPhoneInputProps = {
  rounded?: boolean;
} & Pick<ComponentPropsWithoutRef<typeof FormInput>, 'status' | 'suffix'> &
  ComponentPropsWithoutRef<typeof PhoneInput>;

const FormPhoneInput = forwardRef(
  (
    {
      onChange,
      status = 'default',
      disabled,
      readOnly,
      suffix,
      rounded,
      ...props
    }: FormPhoneInputProps,
    ref: Ref<any>
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    return (
      <div
        className={cx(
          'flex items-center',
          style.base,
          rounded ? style.rounded : undefined,
          style[state]
        )}
      >
        <PhoneInput
          className={cx('placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent')}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          ref={ref}
          defaultCountry="US"
          initialValueFormat="national"
          numberInputProps={{ className: 'focus:outline-none' }}
          {...props}
        />

        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3 flex items-center', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormPhoneInput.displayName = 'FormPhoneInput';

export default FormPhoneInput;
